<script lang="ts" setup>
withDefaults(defineProps<{ invalid?: boolean }>(), { invalid: false });

const slots = defineSlots<{
  default: () => VNode[];
}>();
const render = () => {
  const child = slots.default()[0];
  if (!child) {
    throw new Error('InputStyle.vue needs a child element.');
  }
  return child;
};
</script>

<template>
  <render
    :class="{
      [$style.input]: true,
      [$style['input--invalid']]: invalid,
    }"
  />
</template>

<style lang="scss" module>
.input {
  $root: &;
  box-sizing: border-box;
  font-size: 16px;
  padding: 12px;
  color: $color-text-and-object-high-emphasis;
  background-color: $color-surface-primary;
  border: solid 1px $color-border-low-emphasis;
  border-radius: 4px;
  outline: none;
  transition-property:
    color,
    background-color,
    border-color,
    -webkit-text-fill-color;
  transition-duration: $transition-base-duration;
  transition-timing-function: $transition-base-timing-function;

  &::placeholder {
    color: $color-text-and-object-disable;
  }

  &:focus {
    border-color: $color-border-accent-primary;
  }

  &:disabled {
    color: $color-text-and-object-disable;
    background: $color-surface-tertiary;
    border-color: $color-border-low-emphasis;
  }

  &--invalid {
    color: $color-caution-object;
    border-color: $color-caution-border;
    background-color: $color-caution-surface;

    &:-webkit-autofill {
      color: $color-caution-accent;
      -webkit-text-fill-color: $color-caution-accent;
    }
  }
}
</style>
